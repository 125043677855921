import config from "../config.json"
import httpService from "./httpService"
import logger from "./logService"

const apiEndpoint = config.apiUrl + "/sites"

export const getSite = async (id) => {
  let result = await httpService.get( apiEndpoint + `/${id}` );
  if(!result) return null;
  return result.data
}

export const getSites = async () => {
  let result = await httpService.get( apiEndpoint );
  if(!result) return null;
  return result.data
}

export const createOrUpdate = async (site) => {
  if(site.id){
    return updateSite(site)
  } else {
    return createSite(site)
  }
}

export const updateSite = async ( site ) => {
  let result = await httpService.post( apiEndpoint + `/${site.id}`, site );
  if(!result) return null;
  return result.data;
}

export const verifySite = async ( site ) => {
  let result = await httpService.post( apiEndpoint + `/${site.id}/verify`, site );
  if(!result) return null;
  return result.data;
}

const patchSite = async ( id, payload ) => {
  return await httpService.patch( apiEndpoint + `/${id}`, payload );
}

export const pauseSite = async ( id ) => {
  let result = await patchSite( id,  { isPaused: true } );
  if(!result) return null;
  return result.data;
}

export const resumeSite = async ( id ) => {
  let result = await patchSite( id, { isPaused: false } );
  if(!result) return null;
  return result.data;
}

export const toggleFavorite = async ( id, favState ) => {
  let result = await patchSite( id, { isFavorite: favState } );
  if(!result) return null;
  return result.data;
}

export const crawlNow = async ( id ) => {
  let result = await patchSite( id, { nextCrawlDate: null });
  if(!result) return null;
  return result.data;
}

export const purgeSiteStats = async ( id ) => {
  let result = await patchSite( id, { purgeSiteStats: true });
  if(!result) return null;
  return result.data;
}

export const unlockSite = async ( id ) => {
  let result = await patchSite( id, { isLocked: false } );
  if(!result) return null;
  return result.data;
}

export const createSite = async ( site ) => {
  let result = await httpService.post( apiEndpoint, site  );
  if(!result) return null;
  return result.data;
}

export const deleteSite = async ( id ) => {
  try{
    let result = await httpService.delete( apiEndpoint + `/${id}` );
    if(!result) return false;
    return true;
  } catch (err) {
    logger.log(err);
  }
}

const sitesService = {
  getSite,
  getSites,
  createSite,
  updateSite,
  createOrUpdate,
  deleteSite,
  verifySite,
  pauseSite,
  resumeSite,
  unlockSite,
  toggleFavorite,
  crawlNow,
  purgeSiteStats,
};

export default sitesService;
