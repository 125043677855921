import config from "../config.json"
import httpService from "./httpService"

const statsEndpoint = config.statsApi + "/stats"
const pagesEndpoint = config.statsApi + "/pages"

export const getPages = async (siteid, search) => {
  let result = await httpService.post( statsEndpoint + `/site/${siteid}`, search )
  if(!result) return null;
  return result.data
}


export const getPageDetails = async (url) => {
  let encodedUrl = encodeURIComponent(url)
  let result = await httpService.get( pagesEndpoint + `/${encodedUrl}` );
  if(!result) return null;
  return result.data
}


const pagesService = {
  getPageDetails,
  getPages,
};

export default pagesService;
