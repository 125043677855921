import config from "../config.json"
import httpService from "./httpService"

const pagesEndpoint = config.statsApi + "/pages"
const statsEndpoint = config.statsApi + "/stats"

export const getSiteStats = async (id) => {
  let result = await httpService.get( statsEndpoint + `/site/${id}` );
  if(!result) return null;
  return result.data
}

export const dismissError = async (url) => {
  let result = await httpService.patch( 
    pagesEndpoint + `/${url}`,
    {
      dismissed: true,
    }
  )
  if(!result) return null;
  return result.data
}

export const purgeSite = async (id) => {
  let result = await httpService.post( 
    pagesEndpoint + `/purge/${id}`,
    {
      dismissed: true,
    }
  )
  if(!result) return null;
  return result.data
}

const statsService = {
  getSiteStats,
  dismissError,
  purgeSite,
};

export default statsService;
