import { useContext } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import IconGear from "./common/icons/IconGear";
import IconTable from "./common/icons/IconTable";
import SiteSwitcher from "./SiteSwitcher";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../App'
import IconSite from "./common/icons/IconSite";

const SiteHeader = ({site, root, onSiteSwitch}) => {

  const { sites } = useContext(UserContext);
  if(!root) root = 'sites';
  
  const navigate = useNavigate();
  const switchSite = async ( site ) => {
    // http://localhost:3000/dashboard/sites/63a0b4c4c954b9ebde021eb0
    navigate(`/dashboard/${root}/${site.id}`)
    if(typeof onSiteSwitch === 'function') onSiteSwitch(site);
  }

  return ( 
    <>
    {site && <div className="site-header flex nw">
      <div className="fill">
        {sites && <SiteSwitcher
          sites={sites.filter(site => site.isVerified && site.isActive && !site.isPaused)}
          current={site}
          onSelect={switchSite} /> }
        {!sites && <div className="site-switcher-loading">Loading..</div>}

        <div className="s">Next Crawl: 
          <Moment date={site.nextCrawlDate} fromNow /></div>
      </div>
      <div className="">
        { ( root === 'sites' ) && <Link 
          className="btn btn-icon un-btn"
          to={`/dashboard/pages/${site.id}`}><IconTable /></Link>}
        { ( root === 'pages' ) && <Link 
          className="btn btn-icon un-btn"
          to={`/dashboard/sites/${site.id}`}><IconSite /></Link>}
        <Link 
          className="btn btn-icon un-btn"
          to={ `/dashboard/sites/${site.id}/edit` }><IconGear /></Link>
      </div>
    </div>}
    {!site && <div className="flex nw">
      <div className="fill mr mb">
        <h1><div className="loading inline"></div></h1>

        <div className="s">Next Crawl: <div className="loading inline"></div></div>
      </div>
      <div className=""><div className="loading btn btn-icon un-btn"></div></div>
    </div>}
    </>
  );
}

export default SiteHeader;