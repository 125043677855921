import _ from 'lodash'

const Pagination = ({ itemCount, pageSize, maxLinks, currentPage, onPageChange }) => {

  const pagesCount = Math.ceil(itemCount / pageSize);
  if(pagesCount <= 1) return null;
  const startPage = pagesCount < maxLinks ? 1 : Math.max(1, currentPage - Math.floor(maxLinks/2))
  const endPage = Math.min(pagesCount + 1, startPage + maxLinks)
  const pages = _.range(startPage, endPage);

  return ( 
    <nav className="pagination">
      <ul className="mbs">
        {pages.map(page => 
          <li key={page} className={"page-item " + (currentPage === page ? 'active': '')}>
            <button className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </button>
          </li>
        )}
      </ul>
      <div className="tac sm g">
        <div>Page {currentPage}/{pagesCount}</div>
      </div>
    </nav>
  );
}

Pagination.defaultProps = {
  maxLinks: 7
}

export default Pagination;