import React, { Component } from 'react'
import InputField from './inputField';
import Select from './select';
import Checkbox from './checkbox';
const Joi = require('joi-browser');

export default class Form extends Component {

  state = {
    data: {},
    errors: {}
  }

  validate = () => {
    const { error } = Joi.validate(this.state.data, this.schema, { abortEarly: false });
    if(!error) return null;
    const errors = {};
    for(let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const objSchema = { [name]: this.schema[name] };
    const { error } = Joi.validate( obj, objSchema );
    if(!error) return null;
    return error.details[0].message;
  }

  onChange = ({ target: input }) => {
    const errors = { ...this.state.errors }
    const errorMessage = this.validateProperty(input)
    if( errorMessage ) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };

    if(input.type === 'text'){
      data[input.name] = input.value;
    } else if(input.type === 'checkbox'){
      data[input.name] = input.checked;
    }

    this.setState({ data, errors })
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if(errors) return;

    this.doSubmit();
  }

  renderInput = (name, label, type="text") => {
    const{ data, errors } = this.state;

    return (
      <InputField
        type={type}
        label={label}
        name={name}
        value={data[name]}  
        onChange={(e) => this.onChange(e)}
        error={errors[name] ? errors[name]: null}
        required />
    );
  }

  renderHidden = (name) => {
    const{ data } = this.state;
    return (
      <InputField
        type="hidden"
        label=""
        name={name}
        value={data[name]} />
    );
  }

  renderOptionalInput = (name, label, type="text") => {
    const{ data, errors } = this.state;

    return (
      <InputField
        type={type}
        label={label}
        name={name}
        value={data[name]}  
        onChange={(e) => this.onChange(e)}
        error={errors[name] ? errors[name]: null} />
    );
  }

  renderInputWithDescription = (name, label, description, type="text") => {
    const{ data, errors } = this.state;

    return (
      <InputField
        type={type}
        label={label}
        name={name}
        value={data[name]} 
        description={description}
        onChange={(e) => this.onChange(e)}
        error={errors[name] ? errors[name]: null}
      />
    );
  }

  renderCheckbox = (name, label, description, defaultValue = '') => {
    const{ data, errors } = this.state;

    return (
      <Checkbox
        label={label}
        name={name}
        description={description}
        isChecked={data[name] ? data[name] : defaultValue}
        onChange={(e) => this.onChange(e)}
        error={errors[name] ? errors[name]: null} />
    );
  }

  renderButton = (label) => {
    return (
      <button 
        disabled={this.validate()}
        className="lg" 
        type="submit">{label}</button>
    )
  }

  renderSelect = (name, label, options, fields={key:"key", value:"value"}) => {
    const{ data, errors } = this.state;

    return(
      <Select
        label={label}
        name={name}
        options={options}
        value={data[name]}
        fields={fields}
        onChange={(e) => this.onChange(e)}
        error={errors[name] ? errors[name]: null} />
    )
  }

}
