import React from 'react';
import Form from "./common/form";
const Joi = require('joi-browser');

export default class EditSiteForm extends Form {

  state = {
    data: { 
      name: this.props.site.name || '', 
      domain: this.props.site.domain || '',
      feedKey: this.props.site.feedKey || '',
      crawlLimit: this.props.site.crawlLimit || 100,
      crawlInterval: this.props.site.crawlInterval || 7,
      verificationMethod: this.props.site.verificationMethod || 'plugin',
      sitemapRoot: this.props.site.sitemapRoot || 'sitemap.xml',
      crawlImages: this.props.site.crawlImages || false,
      crawlAMP: this.props.site.crawlAMP || false,
      isUpdate: this.props.site.id !== undefined,
    },
    errors: {}
  };

  schema = {
    name: Joi.string()
      .min(4)
      .max(65)
      .required()
      .regex(/^[a-zA-Z0-9 ]{4,}$/
      , "alphanumeric")
      .label('Site Name'),
    domain: Joi.string()
      .min(4)
      .max(255)
      .regex(/^([a-zA-Z0-9]\.[a-zA-Z]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,})$/
      , "domain")
      .required()
      .label('Site Domain'),
    feedKey: Joi.string()
      .min(0)
      .max(255)
      .regex(/^[a-zA-Z0-9 ]+$/
      , "alphanumeric")
      .label('Feed Key')
      .allow(''),
    crawlLimit: Joi.number().min(0).max(50000),
    crawlInterval: Joi.number().min(1).max(30),
    crawlImages: Joi.any(),
    crawlAMP: Joi.any(),
    verificationMethod: Joi.string().valid('plugin', 'ftp', 'dns'),
    sitemapRoot: Joi.string()
      .min(0)
      .max(255)
      .regex(/^[a-zA-Z0-9-._/]+$/
      , "alphanumeric")
      .allow(''),
    isUpdate: Joi.boolean()
  }

  doSubmit = async () => {
    this.props.onSubmit( {
      id: this.props.site.id, 
      ...this.state.data 
    } );
  }

  render() {

    const buttonLabel = this.props.site && this.props.site.uid ?
      "Update" : "Add"

    return (
      <form className="form" onSubmit={this.handleSubmit}>
      <div className="md-flex">
        <div className="md-c1_2">
        { this.renderInputWithDescription("name", "Site Name", "Friendly name of the site. (E.g. Google)") }
        </div>
        <div className="md-c1_2">
        { this.renderInputWithDescription("domain", "Site Domain", "Domain name with out protocol (E.g. google.com)") }
        </div>
      </div>
      <div className="md-flex">
        <div className="md-c1_2">
          { this.renderSelect(
            "verificationMethod", 
            "Verification Method", 
            [
              {key:"plugin", value:"WP Plugin"},
              {key:"ftp", value:"FTP"},
              {key:"dns", value:"DNS"}
            ]) }
        </div>
        <div className="md-c1_2">
          { this.state.data.verificationMethod === 'plugin' ?
            this.renderInputWithDescription(
              "feedKey", "Feed Key", "Feed key from your WPCrawl feeder plugin settings on WordPress") 
            :
            this.renderInputWithDescription("sitemapRoot", "Sitemap Path", "Path to sitemap (Eg. sitemap.xml)") 
          }</div>
      </div>
      <div className="md-flex">
        <div className="md-c1_2">{ 
          this.renderInputWithDescription(
            "crawlLimit", 
            "Crawl Limit", 
            "Number of pages to crawl at one crawl task") 
        }</div>
        <div className="md-c1_2">{ 
          this.renderInputWithDescription(
            "crawlInterval", 
            "Crawl Frequency (days)", 
            "How often should we crawl your site?") 
        }</div>
        <div className="md-c1_2">{ 
          this.renderCheckbox(
            "crawlImages", 
            "Crawl Images (PRO)", 
            "Do you need images on cache" ) 
        }</div>
        <div className="md-c1_2">{ 
          this.renderCheckbox(
            "crawlAMP", 
            "Crawl AMP Pages (PRO)", 
            "Do you need AMP pages on cache" ) 
        }</div>
      </div>
        <div className="tac">
          { this.renderHidden( "isUpdate" ) }
          { this.renderButton( buttonLabel ) }
        </div>
      </form>
    )
  }
}
