import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import EditSiteForm from '../../components/EditSiteForm'
import sitesService from '../../services/sitesService';
import logger from '../../services/logService';
import IconSite from '../../components/common/icons/IconSite';
import { toast } from 'react-toastify';
import { UserContext } from '../../App';
import statsService from '../../services/statsService';

const EditSite = () => {
  const { onSitesUpdated } = useContext(UserContext);

  const navigate = useNavigate();
  const params = useParams();
  const [site, setSite] = useState()

  useEffect(() => {
    async function loadSiteInformation(){
      const site = await sitesService.getSite( params.id )
      setSite( site )
    }

    loadSiteInformation()
  }, [params.id])


  const createOrUpdateSite = async ( editSiteFormData ) => {
    logger.log( editSiteFormData );
    let site = await sitesService.createOrUpdate( editSiteFormData );
    if( site && site.id ){
      toast.success("Site updated successfully.");
      onSitesUpdated(site)
      navigate("/dashboard");
    }
  }

  const deleteSite = async () => {
    let result = await sitesService.deleteSite( site.id );
    if(result){
      onSitesUpdated(site)
      toast.success("Site deleted successfully.");
      navigate("/dashboard");
    }
  }

  const pauseSite = async () => {
    let result = await sitesService.pauseSite( site.id );
    if(result){
      onSitesUpdated(site)
      toast.success("Site crawl paused.");
      navigate("/dashboard");
    }
  }

  const resumeSite = async () => {
    let result = await sitesService.resumeSite( site.id );
    if(result){
      onSitesUpdated(site)
      toast.success("Site crawl resumed.");
      navigate("/dashboard");
    }
  }

  const unlockSite = async () => {
    let result = await sitesService.unlockSite( site.id );
    if(result){
      onSitesUpdated(site)
      toast.success("Site unlocked successfully.");
      navigate("/dashboard");
    }
  }

  const purgeSite = async () => {
    let result = await statsService.purgeSite( site.id );
    if(result){
      let result = await sitesService.purgeSiteStats( site.id );
      onSitesUpdated(site)
      if('count' in result){
        let deleted = result.count
        toast.success(`Purged ${deleted} pages successfully.`);
      } else {
        toast.success(`Site purged successfully.`);
      }
      
      navigate("/dashboard");
    }
  }

  return (
    <div className="wrap flex">
    <div className="main">
    <div>
      <div className="site-header flex nw">
        <div className="fill">
          <h1>Edit Site</h1></div>
        { (site && site.isVerified) && <div className="">
          <Link
            className="btn btn-icon un-btn"
            to={`/dashboard/sites/${site.id}`}><IconSite /></Link>
        </div>}
      </div>
      {site && 
        <EditSiteForm 
          site={site} 
          onSubmit={createOrUpdateSite}/>
      }
      {site && <div className="mt4">
        {!site.isPaused && 
          <div className="mt2">
            <div className="md-flex">
              <div className="md-c1_3">
                <div className="bx flat bg-warn tac mbs">
                  <button className="btn btn-pause" onClick={() => {if(window.confirm('Are you sure?')){pauseSite()}}}>Pause Site</button>
                </div>
              </div>
              <div className="md-c2_3">
                <p className="help">You may pause your site anytime. This will stop crawler processing your site until it is resumed.</p>
              </div>
            </div>
          </div>}
        {site.isPaused && 
          <div className="mt2">
            <div className="md-flex">
              <div className="md-c1_3">
                <div className="bx flat bg-info tac mbs">
                  <button className="btn btn-resume" onClick={() => {if(window.confirm('Are you sure?')){resumeSite()}}}>Resume Site</button>
                </div>
              </div>
              <div className="md-c2_3">
                <p className="help">Your site is currently paused. Resume your site to re-enable crawler.</p>
              </div>
            </div>
          </div>}
        {site.isLocked && 
          <div className="mt2">
            <div className="md-flex">
              <div className="md-c1_3">
                <div className="bx flat bg-warn tac mbs">
                  <button className="btn btn-unlock" onClick={() => {if(window.confirm('Are you sure?')){unlockSite()}}}>Unlock Site</button>
                </div>
              </div>
              <div className="md-c2_3">
                <p className="help">Your site is currently locked. This may be because of an error on crawler. Use unlock button if it takes longer than expected.</p>
              </div>
            </div>
          </div>}
        <div className="mt2">
          <div className="md-flex">
            <div className="md-c1_3">
              <div className="bx flat bg-danger tac mbs">
                <button className="btn btn-purge" onClick={() => {if(window.confirm('Are you sure? This action can not be undone')){purgeSite()}}}>Purge Site</button>
              </div>
            </div>
            <div className="md-c2_3">
              <p className="help">Use "Purge Site" button to delete all page statistics &amp; data. This action permanently deletes all statistics for your site.</p>
            </div>
          </div>
        </div>
          <div className="mt2">
            <div className="md-flex">
              <div className="md-c1_3">
                <div className="bx flat bg-danger tac mbs">
                  <button className="btn btn-delete" onClick={() => {if(window.confirm('Are you sure?')){deleteSite()}}}>Delete Site</button>
                </div>
              </div>
              <div className="md-c2_3">
                <p className="help">Use "Delete Site" button to remove it from your account. If you add it back later on, you will need to <strong>re-verify your site</strong>.</p>
              </div>
            </div>
            </div>
      </div> }
    </div>
    </div>
    </div>
  )
}

export default EditSite;
