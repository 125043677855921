const CSSGauge = ({percent, color, background}) => {

  return ( 
    <div className="css-gauge">
      <div className="gauge" style={{'--rotation':percent*1.8 + 'deg'}}>
        <div className="percentage"></div>
        <div className="mask"></div>
        <span className="value">{percent}<span className="sign">%</span></span>
      </div>
    </div>
  );
}

export default CSSGauge;