import Moment from 'react-moment';

const NotificationLine = ({note}) => {

  return ( 
    <div>
      <div className={`note note-${note.level}`}>
        <div className="date"><Moment date={note.date} fromNow/></div>
        <div className="title">{note.title}</div>
        <div className="summary">{note.summary}</div>
      </div>
    </div> 
  );
}

export default NotificationLine;