import { Link, useNavigate, useLocation } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import authService from "../services/authService";
import { toast } from "react-toastify";

const Login = () => {

  const navigate = useNavigate();
  const location = useLocation();

  // redirect after login
  const from = location.state?.from?.pathname || "/dashboard";

  const loginRedirect = ( result )  => {
    if( result && result.user ){
      // navigate to from page
      navigate( from, { replace: true } );
    }
  }

  const loginWithGoogle = async () => {
    // login with google
    let result = await authService.loginWithGoogle()
    loginRedirect( result )
  }

  const loginWithEmail = async ( user ) => {
    // login with email
    let {result, err} = await authService.login( user )
    if(!err){
      loginRedirect( result )
    } else {
      toast.error( err.message );
    }
  }

  return ( 
    <div className="hero full">
    <div className="wrap">
      <div className="md-flex">
        <div className="md-c1_2 body">
          <h1>Welcome to WPCrawl</h1>
          <p className="lead">Our cloud based crawler will keep your site warm &amp; ready for every visitor.</p>
          <p className="help">A dynamic page generation takes around 3 seconds, which increase site bounce rates by 20%. Do not miss opportunities because of a slow website.</p>

        </div>
        <div className="md-c1_2">

          <div className="bx p0 w460 br mx-auto mt mb">
            <div className="hdr act flex">
              <div className="p1">Not registered yet?</div>
              <div className="tar ps"><Link className="btn" to="/register">Register</Link></div>
            </div>
        
            <div className="p1">
              <h2>Login</h2>
        
              <div className="p2 tac mb well">
                <button onClick={loginWithGoogle}>
                  Login with Google
                </button>
              </div>

              <p className="tac mt mb">OR login with your email address</p>

              <div className="tac">
                <LoginForm 
                  buttonLabel="Login"
                  onSubmit={loginWithEmail} />
                <Link to="/forgot-password">Forgot password</Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    </div>

  );
}

export default Login;