import { useEffect } from "react";
import authService from "../services/authService";


const Logout = () => {

  useEffect(() => {
    // logout current user
    authService.logout()
    // navigate to site home
    window.location.href = 'https://wpcrawl.com/'; 
    return () => {}
  })

  return (
    <></>
  );
}




export default Logout;