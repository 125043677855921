import { useEffect, useState } from "react"
import Moment from "react-moment"
import { Link, useParams } from "react-router-dom"
import pagesService from "../services/pagesService"
import { roundNumber } from "../util/numberFormatter"

const Page = () => {

  const params = useParams()
  const [page, setPage] = useState()
  const [headers, setHeaders] = useState()

  useEffect(() => {
    async function loadPageDetails(){
      const page = await pagesService.getPageDetails( params.url )
      setPage( page )

      if(page.headers){
        try{
          let o = JSON.parse(page.headers)
          let arr = [];
          for(var i in o)
            arr.push([i, o[i]]);

          if(arr){
            setHeaders(arr)
          }
        } catch {}
      }
    }

    loadPageDetails()
  }, [params])

  let now = new Date()
  let expire_date = new Date(page?.expires)
  
  return ( 
    <>
      <div className="wrap mb">
        { page && <>
          <header className="flex nw mb">
            <div className="fill mr mb">
              <div className="sitelink"><Link className="btn btn" to={`/dashboard/sites/${page.site_id}`}>&laquo; {page.site_domain}</Link></div>
              <h1 className="url">{page.url}</h1>
              <div className="s"><strong>Last Crawl:</strong> <Moment date={page.crawl_date} fromNow /></div>
              { (expire_date && expire_date > now) && <div className="s"><strong>Expires:</strong> <Moment date={page.expires} fromNow /></div>}
            </div>
            <div className=""></div>
          </header>

          <div className="flex">
            <div className="main">
            <div><h4 className="section-title">Status</h4></div>  
            <div className="bx flex mb page-status">
              <div className="row nw">
                <div className={`cell br status status-${page.status}`} title="Response Code">
                  <div className="label">Status</div>
                  <div className="cell status-code">{page.status}</div>
                </div>

                <div className="cell">
                  <div className="label">Page Timing</div>
                  <div className="flex">
                    <div className="br time server_time" title="Initial Response">
                      <div className="label">Server</div>
                      <div className="cell">{roundNumber(page.server_time, 2)}s</div>
                    </div>  
                    <div className="br time roundtrip_time" title="Roundtrip Time">
                      <div className="label">Total</div>
                      <div className="cell">{roundNumber(page.roundtrip_time, 2)}s</div>
                    </div>
                  </div>
                </div>

                <div className="cell cache-status">
                  <div className="label">Cache Status</div>
                  <div className="flex">
                    <div className="" title="Cache Status">
                      <div className="label">Edge</div>
                      <div className={`br cell cf_status cf_status-${page.cf_status}`}>{page.cf_status ?? 'N/A'}</div></div>
                    <div className="" title="Server Status">
                      <div className="label">Server</div>
                      <div className={`br cell cf_status cf_status-${page.ls_status}`}>{page.ls_status ?? 'N/A'}</div></div>
                  </div>
                </div>
              </div>


            </div>
            
            <div><h4 className="section-title">Content</h4></div>  
            <div className="bx mb content-summary">

            <div className="row"><strong>Content Type:</strong><span>{page.content_type}</span></div>
            <div className="row"><strong>First Discovered:</strong> <Moment date={page.discovered} fromNow /></div>
            { page.last_modified && <div className="row"><strong>Last Modified:</strong> <Moment date={page.last_modified} fromNow /> </div>}
            </div>

            { 
              headers && <>
              <div className="section-title"><h4>Page Headers</h4></div>
              <div className="bx mb table page-headers">
              
                {headers?.map((h,i) => 
                  <div key={`${i}`} className="row">
                    <div className="cell header">{h[0]}</div>
                    <div className="cell lt">{h[1]}</div>
                  </div>
                )}
              
              </div>
              </>
            }
            </div>
          </div>

        </>}
      </div>
    </>
  );
}

export default Page;