import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import sitesService from '../services/sitesService';
import { percent } from "../util/percent";
import CSSGauge from "./common/CSSGauge";
import IconBolt from "./common/icons/IconBolt";
import IconGear from "./common/icons/IconGear";
import IconHeart from "./common/icons/IconHeart";
import IconHeartFull from "./common/icons/IconHeartFull";
import logger from "../services/logService";
import { UserContext } from "../App";
import Moment from "react-moment";

const Card = ({ site }) => {
  let { id, name, domain, isFavorite, isVerified, verificationMethod, verificationKey, feedKey, isPaused, isLocked, hasError, errorMessage, stats, nextCrawlDate } = site

  const [ isSending, setIsSending ] = useState(false)
  const [ isVerifiedNow, setIsVerifiedNow ] = useState(isVerified)
  const [ favState, setFavState ] = useState(isFavorite)
  const { appUser } = useContext(UserContext);

  const canVerify = () => {
    if(verificationMethod === 'plugin'){
      return '' !== feedKey && null !== feedKey
    } else if ( verificationMethod === 'ftp' ){
      return verificationKey !== '' && verificationKey !== null
    } else if ( verificationMethod === 'dns' ){
      return verificationKey !== '' && verificationKey !== null
    } 
    return false
  }

  const verifySite = async () => {
    setIsSending(true)
    try{
      const res = await sitesService.verifySite(site)
      if(res && res.isVerified){
        setIsVerifiedNow(true)
      }
    } catch(e){
      toast.error("Verification failed. Please check feed key.")
    }
    setIsSending(false)
  }

  const toggleFavorite = async () => {
    setIsSending(true)
    try{
      const res = await sitesService.toggleFavorite(site.id, !site.isFavorite)
      if(res){
        site.isFavorite = res.isFavorite
        setFavState(site.isFavorite)
      }
    } catch(e){
      logger.log("Favorite failed", e)
    }
    setIsSending(false)
  }

  const crawlNow = async () => {
    setIsSending(true)
    try{
      const res = await sitesService.crawlNow(site.id)
      if(res){
        logger.log(res)
        toast.success("Crawl request sent")
        site.nextCrawlDate = new Date().toISOString().split('.')[0]+"Z"
      }
    } catch(e){
      toast.warning("Crawl now request failed")
      logger.log("Crawl now failed", e)
    }
    setIsSending(false)
  }

  let now = new Date()
  let nextcrawl = new Date(nextCrawlDate)

  return (
    <div className={`site bx br ${favState? 'favorite': ''} ${isLocked? 'locked': ''} ${isPaused? 'paused': ''} ${isVerifiedNow? 'verified': 'z0'} ${hasError? 'has-error': ''} ${isSending? 'sending': ''} `}>
      <div className="flex nw">
        <div className="fill ofh">
          <div className="mbs">{isVerifiedNow ? 
            <h3 className="d-inline"><Link to={`/dashboard/sites/${id}`}><strong>{name}</strong></Link>
            </h3> :
            <h3 className="d-inline"><strong>{name}</strong></h3>
          }  </div>
          <div className="domain mbs"><div>
            {isVerifiedNow ? 
              <a href={`https://${domain}`} target="_blank" rel="noopener noreferrer">{domain}</a> :
              domain
            }</div>
          </div>

          { (nextcrawl > now && !isPaused && isVerifiedNow ) && <div>Next Crawl: <Moment date={nextCrawlDate} fromNow/></div>}
          { (nextcrawl < now && !isPaused && isVerifiedNow ) && <div>Crawling..</div>}

            { !isVerifiedNow && 
              <>
                {verificationMethod === 'dns' && 
                  <p className="text desc s">Create a TXT record on your DNS provider with value <code>wpcrawl-site-verification={verificationKey}</code> and click verify to complete verification.</p>}

                {verificationMethod === 'ftp' && 
                  <p className="text desc s">Create a text file named <code>wpcrawl-{verificationKey}.txt</code> at the root of your site and click verify to complete verification.</p>}

                {verificationMethod === 'plugin' &&
                  (
                  (feedKey === null || feedKey === '' ) === true && 
                    <p className="text desc s">Please <Link to={ `/dashboard/sites/${id}/edit` }>enter your feed key</Link> in site settings to verify your site. <a href="https://wpcrawl.com/how-to-verify" target="_blank" rel="noreferrer">How to verify?</a></p>
                  )
                }

                {verificationMethod === null &&
                  <p className="text desc s">Please <Link to={ `/dashboard/sites/${id}/edit` }>select a verification method</Link> in site settings to verify your site. <a href="https://wpcrawl.com/how-to-verify" target="_blank" rel="noreferrer">How to verify?</a></p>
                }
              </>
            }
        </div>
        <div className="flex col">
          {isVerifiedNow &&
            <div className="tac">
              { stats && <Link to={`/dashboard/sites/${site.id}`}>
                <CSSGauge percent={percent(stats.totalCachedPages, stats.totalPages)} /></Link> }
            </div>
          }
        </div>
      </div>
      <div className="flex aic">
        <Link to={ `/dashboard/sites/${id}/edit` } className="btn btn-sm un-btn"><IconGear /></Link> 
        <button onClick={toggleFavorite} className={`btn btn-sm un-btn ${favState? 'favorite': ''}`}>{ favState? <IconHeartFull /> : <IconHeart /> }</button>
        <div className="fill"></div>
        { (isVerifiedNow && !appUser.creditExpired) ? 
          <button disabled={isSending} onClick={crawlNow} className="btn btm-block btn-crawl"><IconBolt /> Crawl Now</button>
          : 
          (isVerifiedNow && <button title="User credit expired" disabled className="btn btm-block btn-crawl"><IconBolt /> Crawl Now</button> ) }
        {!isVerifiedNow && (
          canVerify() ? <button disabled={isSending} onClick={verifySite} className="btn btn-verify">Verify</button>
          : <></>
        )}
      </div>
      { (isVerified && hasError) && <div className="mt bg-danger err ps">Crawl Error: {errorMessage}</div>}
    </div>
  );
}

export default Card;