import config from "../config.json"
import httpService from "./httpService"

const apiEndpoint = config.apiUrl + "/notifications"

export const getNotification = async (id) => {
  let result = await httpService.get( apiEndpoint + `/${id}` );
  if(!result) return null;
  return result.data
}

export const getNotifications = async () => {
  let result = await httpService.get( apiEndpoint );
  if(!result) return null;
  return result.data
}

const patchNotification = async ( id, payload ) => {
  return await httpService.patch( apiEndpoint + `/${id}`, payload );
}

export const markRead = async ( id ) => {
  let result = await patchNotification( id,  { isSeen: true } );
  if(!result) return null;
  return result.data;
}

export const markUnread = async ( id ) => {
  let result = await patchNotification( id, { isSeen: false } );
  if(!result) return null;
  return result.data;
}

const notificationService = {
  getNotifications,
  getNotification,
  markRead,
  markUnread,
};

export default notificationService;
