import { percent } from "../util/percent";
import CSSProgress from "./common/CSSProgress";

const QuotaUsage = ({ usage, quota }) => {
  return ( 
    <div> 
      <CSSProgress
      percent={percent(usage,quota)} />{usage} pages / {quota}
    </div>
  );
}

export default QuotaUsage;