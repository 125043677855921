import React from 'react'
import { compactNumber } from '../../util/numberFormatter';

export default function Counter({label, count, prefix, suffix, precision, className, compact} = {}) {

  if( count === undefined ) count = 0;
  if( className === undefined ) className = '';
  if( precision === undefined ) precision = 1;
  if( compact === undefined ) compact = false;
  if( count > 0 ) count = Math.round(count * Math.pow(10,precision))/Math.pow(10,precision);

  let numberDisplay = count
  if(compact){ numberDisplay = compactNumber(count, precision) }

  return (
    <div className={`counter ${className}`}>
      <div className="value">{prefix}{numberDisplay}{suffix}</div>
      <div className="label">{label}</div>
    </div>
  )
}
