
const ToggleButton = ({name, value, children, className, type, onToggle, ...rest}) => {

  return (
    <button
      className={ className + ' btn-toggle' + (value === 'on' ? ' btn-toggle-on': '')}
      name={name}
      id={name}
      type={type}
      {...rest}
      onClick={onToggle}
    >{children}</button>
  );
}

export function toggleState(value, onValue){
  if(value === onValue){
    return 'on';
  } else {
    return 'off';
  }
}

export default ToggleButton;