import {useState} from 'react'
import IconFilter from './icons/IconFilter';

const Expander = ({children}) => {
  const [open, setOpen] = useState()
  return ( 
    <div className={'expander' + (open ? ' open' : '')} >
      <div className="expander-icon"
        onClick={()=>setOpen(!open)}><IconFilter /></div>
      {children}
    </div>
  );
}

export const ExpanderBody = ({children}) => {
  return ( 
    <div className="expander-body">
      {children}
    </div>
  );
}

export default Expander;