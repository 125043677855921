import React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { roundNumber } from '../util/numberFormatter'
import { pathOnly } from '../util/pathOnly'
import IconClose from './common/icons/IconClose'
import IconOpenExternal from './common/icons/IconOpenExternal'

const PageStat = ({ page, allowDismiss, onDismiss }) => {
  let { url, status, cf_status, server_time, roundtrip_time, crawl_date, dismissed, ls_status, last_modified } = page

  server_time = roundNumber(server_time, 2)
  roundtrip_time = roundNumber(roundtrip_time, 2)
  let encodedUrl = encodeURIComponent(url)

  if(!status) status='N/A'
  if(!ls_status) ls_status='N/A'
  if(!cf_status) cf_status='N/A'

  const statusClass = status => {
    if(!status) return 'na'
    return status.toString().toLowerCase().replace('/', '')
  }

  return (
    <div className="page-stat mbs">
      <div className="flex nw">
        <div className="fill">
          <div className="url"><Link to={`/dashboard/page/${encodedUrl}`}>{pathOnly(url)}</Link></div>
        </div>
        <div>
          <a href={url} target="_blank" 
            className="btn btn-icon un-btn" 
            rel="noreferrer"><IconOpenExternal /></a></div>
        {(allowDismiss && !dismissed) &&
          <div><button 
            className="btn btn-icon un-btn" 
            onClick={onDismiss}><IconClose /></button></div>
        }
      </div>
      <div className="row flex nw">
        <div className={`cell br status status-${statusClass(status)}`} title="Return Code">{status}</div>
        <div className="cell br time server_time" title="Initial Response">{server_time}</div>  
        <div className="cell br time roundtrip_time" title="Roundtrip Time">{roundtrip_time}</div>
        <div className={`cell br cf_status cache-${statusClass(cf_status)}`} title="Cache Status">{cf_status}</div>
        <div className={`cell br ls_status cache-${statusClass(ls_status)}`} title="Cache Status">{ls_status}</div>
        <div className="cell fill" title="Crawl Date">
          {crawl_date && <small><Moment date={crawl_date} fromNow/></small>}
          {!crawl_date && <small>Queued for crawling</small>}
        </div>
      </div>
    </div>
  )
}

export default PageStat;