import React from 'react';
import Form from "./common/form";
const Joi = require('joi-browser');

export default class AddSiteForm extends Form {

  state = {
    data: { 
      name: this.props.site.name || '', 
      domain: this.props.site.domain || '',
    },
    errors: {}
  };

  schema = {
    name: Joi.string()
      .min(4)
      .max(65)
      .required()
      .regex(/^[a-zA-Z0-9 ]{4,}$/
      , "alphanumeric")
      .label('Site Name'),
    domain: Joi.string()
      .min(4)
      .max(255)
      .regex(/^([a-zA-Z0-9]\.[a-zA-Z]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,})$/
      , "domain")
      .required()
      .label('Site Domain'),
  }

  doSubmit = async () => {
    this.props.onSubmit( {
      id: this.props.site.id, 
      ...this.state.data 
    } );
  }

  render() {

    const buttonLabel = this.props.site && this.props.site.uid ?
      "Update" : "Add"

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        { this.renderInputWithDescription("name", "Site Name", "Friendly name of the site. (E.g. Google)") }
        { this.renderInputWithDescription("domain", "Site Domain", "Domain name with out protocol (E.g. google.com)") }
        <div className="tac">
          { this.renderButton( buttonLabel ) }
        </div>
      </form>
    )
  }
}
