import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from '../App'
import Pagination from "../components/common/pagination";
import PageStat from "../components/PageStat";
import SiteHeader from "../components/SiteHeader";
import pagesService from "../services/pagesService";
import sitesService from "../services/sitesService";
import debounce from 'lodash.debounce'
import { useMemo } from 'react';
import ToggleButton, { toggleState } from "../components/common/toggleButton";
import Expander, { ExpanderBody } from "../components/common/expander";

const Pages = () => {

  const params = useParams();
  const navigate = useNavigate();

  const { user, appUser, sites } = useContext(UserContext);
  const [pages, setPages] = useState()
  const [totalPages, setTotalPages] = useState()
  const [site, setSite] = useState()
  const [search, setSearch] = useState()
  const [filters, setFilters] = useState({
    take: 25,
    sortBy: 'date',
    page: 1,
    status: null
  })

  const [loading, setLoading] = useState(true)

  const onSiteSwitch = async ( site ) => {
    setSearch('')
    replaceFilter({page:1})
  }

  useEffect(() => {
    async function loadSiteInformation(){
      const site = await sitesService.getSite( params.id )
      setSite( site )
    }

    loadSiteInformation(params.id)
  }, [params.id])


  useEffect(() => {
    if(!search) return;
    const setSearchTimer = setTimeout(() => {
      setSearch(search)
      replaceFilter({page:1})
    }, 500)
    return () => clearTimeout(setSearchTimer)
  }, [search])


  useEffect(() => {
    if(!params.id) return;
    async function loadData(){
      filters.search = search
      const data = await pagesService.getPages( params.id, filters )
      setPages(data.pages)
      setTotalPages(data.count)
      setLoading(false)
    }

    loadData()
  }, [params.id, filters, search])


  const toggleFilter = (filter) => {
    Object.keys(filter).forEach( (key, i, s) => {
      if(filters[key] === filter[key]){
        filters[key] = ''
      } else {
        filters[key] = filter[key]
      }
    })

    setFilters({ ...filters })
  }


  const searchHandler = searchTerm => {
    if(!searchTerm || searchTerm.length <= 3) return setSearch('');
    setSearch(searchTerm)
  }


  const doSearch = useMemo(
    () => debounce(searchHandler, 300)
  , []);

  const replaceFilter = (filter) => {
    setFilters({ ...filters, ...filter })
  }

  const handlePageChange = (page) => {
    setFilters({ ...filters, page })
  }


  return ( 
    <div className="wrap">
      {!loading && 
        <div id="pages">
          <SiteHeader
            site={site} 
            root="pages"
            onSiteSwitch={onSiteSwitch} />
        <div className="tlbx sticky mbs">
          <Expander>
            <input type="text" 
              onChange={(e) => doSearch(e.target.value)} 
                placeholder="Search.." />
            <ExpanderBody>
            <div className="toggle-wrap">Response Code: 
          <ToggleButton 
            className="btn btn-sm"
            value={toggleState(filters.status,200)}
            onToggle={() => {toggleFilter({"status": 200})}}
            >200</ToggleButton>

          <ToggleButton 
            className="btn btn-sm"
            value={toggleState(filters.status,301)}
            onToggle={() => toggleFilter({"status": 301})}
            >301</ToggleButton>

          <ToggleButton 
            className="btn btn-sm"
            value={toggleState(filters.status,404)}
            onToggle={() => toggleFilter({"status": 404})}
            >404</ToggleButton>

          <ToggleButton 
            className="btn btn-sm"
            value={toggleState(filters.status,500)}
            onToggle={() => toggleFilter({"status": 500})}
            >500</ToggleButton>
        </div>
        <div className="toggle-wrap">Cache Status:
          <ToggleButton 
            className="btn btn-sm"
            value={toggleState(filters.cf_status,"hit")}
            onToggle={() => toggleFilter({"cf_status": "hit"})}
            >hit</ToggleButton>
          <ToggleButton 
            className="btn btn-sm"
            value={toggleState(filters.cf_status,"miss")}
            onToggle={() => toggleFilter({"cf_status": "miss"})}
            >miss</ToggleButton>
        </div>

        <div className="toggle-wrap">Content Type: 

          <ToggleButton 
            className="btn btn-sm"
            value={toggleState(filters.content_type,"html")}
            onToggle={() => toggleFilter({"content_type": "html"})}
            >HTML</ToggleButton>
          <ToggleButton 
            className="btn btn-sm"
            value={toggleState(filters.content_type,"image")}
            onToggle={() => toggleFilter({"content_type": "image"})}
            >Image</ToggleButton>
        </div>
            </ExpanderBody>
          </Expander>

          
        </div>
        { pages && 
          <div>
            <ul>
            { pages.map(page => 
              <li key={page.ID}>
                <PageStat page={page} />
              </li>
            )}
            </ul>
            
            <Pagination 
              itemCount={totalPages}
              currentPage={filters.page}
              pageSize={25}
              onPageChange={handlePageChange} />
          </div>
          }
        </div>}
      { loading && <>Loading ..</>}
    </div>
  );
}

export default Pages;