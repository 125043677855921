import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CSSProgressStepped from "../components/common/CSSProgressStepped";
import Counter from "../components/dashboard/Counter";
import PageStat from "../components/PageStat";
import sitesService from "../services/sitesService";
import statsService from "../services/statsService";
import { percent } from "../util/percent";
import { toast } from "react-toastify";
import SiteHeader from "../components/SiteHeader";

const Site = () => {

  const params = useParams();
  const [site, setSite] = useState()
  const [stats, setStats] = useState()
  const [recentPages, setRecentPages] = useState()
  const [slowPages, setSlowPages] = useState()
  const [errorPages, setErrorPages] = useState()
  const [cachePercent, setCachePercent] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function loadSiteInformation(){
      const site = await sitesService.getSite( params.id )
      setSite( site )
    }

    loadSiteInformation(params.id)
  }, [params.id])


  useEffect(()=> {

    if(!site) return;

    async function loadSiteStats(){

      // initially reset all
      setRecentPages(undefined)
      setSlowPages(undefined)
      setErrorPages(undefined)
      setCachePercent(0)
      setLoading(true)

      // load site stats
      const stats = await statsService.getSiteStats( site.id )
      setStats( stats )

      if(stats.recentPages && stats.recentPages.length > 0 ) {
        setRecentPages( stats.recentPages )
      }

      if(stats.slowPages && stats.slowPages.length > 0){
        setSlowPages( stats.slowPages )
      }

      if(stats.errorPages && stats.errorPages.length > 0){
        setErrorPages( stats.errorPages )
      }

      if(stats.totalCachedPages){
        setCachePercent(stats.totalCachedPages / stats.totalPages * 100)
      }

      setLoading(false)
    }

    loadSiteStats()
  }, [site])


  const dismissError = async ( page ) => {
    const encodedUrl = encodeURIComponent( page.url )
    try{
      const res = await statsService.dismissError(encodedUrl)
      if(res){
        page.dismissed = true
        setErrorPages(errorPages.filter((p) => {
          return p.url !== page.url
        }))
      }
    } catch(e){
      toast.error("Action failed. Please try again")
    }
  }


  return ( 
    <div className="wrap mb">
      { site && <div className="dashboard">
        <div className="">
          <SiteHeader
            site={site}
            root="sites" />
            
          { (stats && stats.totalPages > 0) && 
            <div className="md-flex nw">

              <CSSProgressStepped 
                steps={ [
                  { 
                    value: percent( stats.totalErrors, stats.totalPages ), 
                    title: "Errors",
                    color: "#c00"
                  },
                  {
                    value: percent( stats.queuedPages, stats.totalPages ), 
                    title: "Queued",
                    color: "#ccc"
                  },
                  { 
                    value: percent( stats.totalCachedPages, stats.totalPages ),
                    title: "Cached",
                    color: "#7ddb11"
                  },
                  
                ] }
                minLabel={`${stats.totalCachedPages} cached, ${stats.queuedPages} on queue`}
                maxLabel={`/ ${stats.totalPages} pages`}
              />

              <div className="flex">
                <div className="flex mbs mrs">
                  <Counter label="Cache %" count={cachePercent} />
                  <Counter label="Avg.Time" count={stats.averageRoundtripTime} precision="2" suffix="s" />
                  <Counter label="Errors" count={stats.totalErrors} />
                </div>
                <div className="fill"></div>
              </div> 
            </div> 
          }

          { stats && 
            <div className="stats mb">
              
              { (errorPages && errorPages.length > 0 ) && <div className="mt">
                <h3>Errors</h3>
                <p className="help">Following pages returned an error response while crawling. Check out our guide about common HTTP errors and how to fix them.</p>
                  <ul className="sm-exp">
                    { errorPages.map( page => (
                        !page.dismissed && 
                        <li key={page.ID}>
                          <PageStat 
                            page={page} 
                            allowDismiss={true} 
                            onDismiss={() => dismissError(page)}
                            />
                        </li>
                      ))
                    } 
                  </ul>
                </div> }
              <div className="flex">
                
                { recentPages && <div className="md-c1_2 mt">
                    <h3>Recent Crawl Results</h3>
                    <ul className="sm-exp">
                      { recentPages.map( page => (
                          <li key={page.ID}>
                            <PageStat page={page} />
                          </li>
                        ))
                      } 
                    </ul>
                  </div> }
                { slowPages && <div className="md-c1_2 mt">
                    <h3>Slow Pages</h3>
                    <ul className="sm-exp">
                      { slowPages.map( page => (
                          <li key={page.ID}>
                            <PageStat page={page} />
                          </li> 
                        ))
                      }
                    </ul>
                  </div> }
              </div>
            </div>
          }
          { (!loading && (!stats || !recentPages)) && <div className="text bx flat tac">Congratulations! Your website is now verified and queued for crawling. You will start seeing results here soon!</div> }
        </div> 
      </div> }
    </div>
  );
}

export default Site;