const CSSProgress = ({percent, text}) => {

  let hasText = true;
  if(!text){
    text=percent;
    hasText = false;
  }

  return ( 
    <div className="css-progress">
      <div className="progress-bar">
        <div className="progress-bar-inner" style={{width: percent + '%'}}>
          <div className="progress-bar-text">{text}{ !hasText && <span className="progress-bar-sign">%</span>}</div></div>
      </div>
    </div>
  );
}

export default CSSProgress;