import React from 'react';
import SiteCard from './SiteCard';

const SiteGrid = ({ sites, user }) => {
  return (
    <ul className="flex">
    {sites && sites.map( site => (
      <li key={site.id} className="md-c1_2 mb1">
        <SiteCard site={site} user={user} />
      </li>
    ))}
    {!sites && <li>Loading..</li>}
    </ul>
  );
}

export default SiteGrid;