import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { UserContext } from '../../App';
import AddSiteForm from '../../components/AddSiteForm';
import sitesService from '../../services/sitesService';

export default function NewSite() {

  const { sites, onSitesUpdated } = useContext(UserContext);
  const navigate = useNavigate();

  const createOrUpdateSite = async ( editSiteFormData ) => {
    let site = await sitesService.createOrUpdate( editSiteFormData );

    if( site && site.id ){
      onSitesUpdated(site)
      toast.success("Site added successfully.");
      navigate("/dashboard");
    }
  }

  return (
    <div className="wrap flex">
    <div className="main">
    <div>
      <div className="tlbx"><Link className="btn btn-sm" to="/dashboard">&laquo; Back</Link></div>
      <h1>Add New Site</h1>
      <AddSiteForm
        site={{}} 
        onSubmit={createOrUpdateSite}
        />
    </div>
    </div>
    </div>
  )
}
