import { useState } from 'react'
import IconSwitch from './common/icons/IconSwitch'

const SiteSwitcher = ({sites, current, onSelect}) => {

  const [expanded, setExpand] = useState()

  const selectSite = (s) => {
    if( expanded ){
      onSelect(s)
    } 
    setExpand(!expanded)
  }

  const toggle = () => {setExpand(!expanded)}

  return ( 
    <div className={ 'switcher ' + (expanded ? 'expanded':'') }>
      <div className="switcher-icon"><IconSwitch /></div>
      <div className="switcher-option" onClick={toggle}>{current.name}</div>
      {sites && sites.map((site) => (
        <div key={site.id} className={ 'switcher-option ' + (site.name === current.name ? 'current':'') } onClick={(e) => selectSite(site)}>{site.name}</div>
        ))}
    </div>
  );
}

export default SiteSwitcher;