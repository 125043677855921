import React, { createContext, useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logger from "./services/logService";

import './App.css';
import Layout from './pages/Layout';
import NotFound from './pages/NotFound';
import Site from './pages/Site';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Register from './pages/Register';
import authService from './services/authService';
import Dashboard from './pages/Dashboard';
import NewSite from './pages/sites/NewSite';
import { useState } from 'react';
import { ProtectedLayout } from './pages/ProtectedLayout';
import { HomeLayout } from './pages/HomeLayout';
import EditSite from './pages/sites/EditSite';
import ForgotPassword from './pages/ForgotPassword';
import Page from './pages/Page';
import Pages from './pages/Pages';
import sitesService from './services/sitesService';

export const UserContext = createContext(null)

function App() {

  const [user, loading] = authService.useAuth()
  const [appUser, setAppUser] = useState(null)
  const [sites, setSites] = useState([])

  useEffect(() => {
    if ( loading ) return;
    async function loadUserDetails(){
      // query app user details
      logger.log("App.getAppUser")
      let appUser = await authService.getAppUser(user)
      if( appUser ){
        setAppUser( appUser )
      } else {
        logger.log("!!! app user get failed !!!")
        authService.logout()
      }
    }

    if(user) loadUserDetails();
  }, [user, loading]);

  function onLoggedIn(user) {
    logger.log("userUpdated", user)
    //setUser(user)
  }

  function onSitesUpdated(site){
    logger.log("sitesUpdated", site)
    reloadSites()
  }

  const reloadSites = async () => {
    if(!user) return;
    const sites = await sitesService.getSites()
    setSites( sites )
  }

  // load sites
  useEffect(() => {
    const loadSites = async () => {
      if(!user) return;
      const sites = await sitesService.getSites()
      setSites( sites )
    }
    loadSites()
    return () => { setSites(null) }
  }, [user])

  const account = useMemo(() => {
		return { user, loading, appUser, sites, setSites, onSitesUpdated, onLoggedIn };
	}, [user, appUser, sites]);

  return (
    <UserContext.Provider value={account}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Layout /> } >
            <Route element={ <HomeLayout /> } >
              <Route index element={ <Register /> } />
              <Route path="/login" element={ <Login /> } />
              <Route path="/forgot-password" element={ <ForgotPassword /> } />
              <Route path="/register" element={ <Register /> } />
            </Route>

            <Route path="/dashboard" element={ <ProtectedLayout /> } >
              <Route path="" element={ <Dashboard /> } />
              <Route path="sites/:id/edit" element={ <EditSite /> } />
              <Route path="sites/new" element={ <NewSite /> } />
              <Route path="sites/:id" element={ <Site /> } />
              <Route path="page/:url" element={ <Page /> } />
              <Route path="pages/:id" element={ <Pages /> } />
            </Route>
            <Route path="*" element={ <NotFound /> } />
            <Route path="/logout" element={ <Logout /> } />
          </Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
    
  );
}

export default App;
