const CSSProgressStepped = ({steps, minLabel, maxLabel}) => {

  return ( 
    <div className="css-progress css-progress-stepped">
      <div className="progress-bar progress-bar-flex">

      { steps && steps.map( (step, i) => (
        <div 
          key={i} 
          className="progress-bar-step" 
          style={{width: step.value + '%', backgroundColor: step.color? step.color: '#eee'}}
          title={step.title?step.title:''}><span>{step.title?step.title:''}</span></div>
      ))}

      </div>
      <div className="flex">
        <div className="css-progress-label">{minLabel}</div>
        <div className="css-progress-label">{maxLabel}</div>
      </div>
    </div>
  );
}

export default CSSProgressStepped;