import React from 'react';
import Notification from './Notification';

const NotificationList = ({ notifications }) => {
  return (
    <ul>
    {notifications && notifications.map( note => (
      <li className="mbs" key={note.id}>
        <Notification note={note} />
      </li>
    ))}
    {!notifications && <li>Loading..</li>}
    </ul>
  );
}

export default NotificationList;