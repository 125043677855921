import React from 'react'
import { useContext } from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../App'
import CSSProgress from '../components/common/CSSProgress'
import NotificationList from '../components/NotificationList'
import SiteGrid from '../components/SiteGrid'
import notificationService from '../services/notificationService'
import { percent } from '../util/percent'
import Widget from '../components/Widget'
import QuotaUsage from '../components/Usage'
import { formatDate } from '../util/timeFormatter'

export default function Dashboard() {

  const { user, appUser, sites } = useContext(UserContext);
  const [ notifications, setNotifications ] = useState([])
  const [ sitesCount, setSiteCount ] = useState(null)
  const [ canAddSite, setCanAddSite ] = useState(false)
  const [ needUpgrade, setNeedUpgrade ] = useState(false)

  const [ favorites, setFavorites ] = useState(false)
  const [ otherSites, setOtherSites ] = useState(false)
  const [ pendingSites, setPendingSites ] = useState(false)

  useEffect(() => {
    if( !appUser ) return;
    if( sites && sites.length < appUser.siteLimit ){
      setCanAddSite(true)
      setNeedUpgrade(false)
    } else {
      setNeedUpgrade(true)
      setCanAddSite(false) 
    }
  }, [appUser, sites])

  useEffect(() => {

    if(!user && !sites) return;
    if(sites){
      setSiteCount( sites.length )
      setFavorites( sites.filter( site => site.isFavorite ) )
      setOtherSites( sites.filter( site => ( site.isVerified && !site.isFavorite ) ) )
      setPendingSites( sites.filter( site => !site.isVerified ))
    }

    let loadNotifications = async () => {
      if(!user) return;
      const notifications = await notificationService.getNotifications()
      setNotifications( notifications )
    }

    loadNotifications();
  }, [user, sites])

  const userLoaded = appUser && user;
  const hasFavorites = favorites.length > 0;
  const siteLimit = appUser?.siteLimit;

  return (
    <div className="wrap flex">
    <div className="main">
    { userLoaded &&
      <div>
        <h1>Welcome {user.displayName}!</h1>

        <div className="tlbx mbs tar flex aic">
          { canAddSite &&
            <Link to="/dashboard/sites/new" className="btn">Add Site</Link> 
          }

          { needUpgrade &&
            <a href="https://wpcrawl.com/pricing/" className="btn btn-upgrade">Upgrade</a>
          }

          { needUpgrade &&
            <div className="fill tal pxs g s">You have reached your site limit. Upgrade your account to add more sites.</div>
          }

          { (sitesCount && !needUpgrade) === true && <div className="site-limit">
            <CSSProgress 
              percent={percent(sitesCount,siteLimit)}
              text={sitesCount + '/' + siteLimit} />
          </div> }
        </div>
        { appUser.creditExpired &&
          <div className="bx flat bg-warn mbs flex">
            <p className="text fill mr">Your have used all your crawl credits for this month. <a href="https://wpcrawl.com/pricing/">Buy credits</a> or <a href="https://wpcrawl.com/pricing/">upgrade your account</a> to continue crawling. Your credits will be reloaded on {formatDate(appUser.creditReloadDate)}.</p>
          </div>
        }

        { hasFavorites && <>
          <div className="mb">
            <h3>Favorite Sites</h3>
            <SiteGrid sites={favorites} />
          </div>
        </> }

        { otherSites.length > 0 && <>
          <div className="mb">
            {hasFavorites ? <h3>Other Sites</h3> : <h3>Sites</h3>}
            <SiteGrid sites={otherSites} />
          </div> 
        </> }

        { pendingSites.length > 0 && <div>
            <h3>Unverified Sites</h3>
            <SiteGrid sites={pendingSites} />
          </div>}

        { !sitesCount && <li className="text bx flat tac">You don't have any sites yet. <br />Add your first site to get started.</li>}
      </div>
    }
    </div>
    <div className="sb">
      { appUser && 
        <Widget title="Usage">
          <QuotaUsage 
            usage={appUser.crawlUsage} 
            quota={appUser.crawlLimit} />
        </Widget>
      }

      <NotificationList 
        notifications={notifications} />
    </div>
    </div>
  )
}
