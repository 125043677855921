import React from 'react'

export default function Footer() {
  return (
    <div className="wrap tac">
      <div className="g s"><div className="menu"><a href="https://wpcrawl.com/privacy-policy/">Privacy</a> • <a href="https://wpcrawl.com/terms-and-conditions/">Terms</a> • <a href="https://wpcrawl.com/contact/">Contact</a></div></div>
      <div className="mt mb p1">2023 &copy; WPCrawl</div>
    </div>
  )
}
