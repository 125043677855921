import React from 'react';
import { Link } from 'react-router-dom';

const NavMenu = () => {
  return ( 
    <React.Fragment>
    <input type="checkbox" id="dw-cb" />
    <div className="dw" id="dw">
      <div className="dw-hdr">
        <Link to="/" className="logo">WPCrawl</Link>
        <label className="x" htmlFor="dw-cb" tabIndex="1">&times;</label>
      </div>
      <nav className="dw-nav">
        <ul>
          <li><Link to="/logout">Logout</Link></li>
        </ul>
      </nav>
      <div className="p1 tac s">
        <small>&copy; 2022. All rights reserved.</small>
      </div>
    </div>
    <div className="o"><label className="o-x" htmlFor="dw-cb"></label></div>
    </React.Fragment>
  );
}

export default NavMenu;