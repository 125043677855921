// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import logger from "./logService";

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";

import config from "../config.json"
import logService from "./logService";
import httpService from "./httpService";


const firebaseConfig = {
  apiKey: "AIzaSyCWS-ta095d1j2uAzN__EY_Xnv4A6IP3rc",
  authDomain: "wpcrawl.firebaseapp.com",
  projectId: "wpcrawl",
  storageBucket: "wpcrawl.appspot.com",
  messagingSenderId: "283901123262",
  appId: "1:283901123262:web:8d00797fe05b78e3e7dcef",
  measurementId: "G-9GT85ZBYHT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const tokenKey = 'wpcrawl-token';

const apiEndpoint = config.apiUrl + "/auth"

onAuthStateChanged(auth, (user) => {
  logger.log("onAuthStateChanged!!!")
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    refreshToken()
    // ...
  } else {
    // User is signed out
    user = false
  }
});

const login = async ( user ) => {
  try {
    logger.log("authService.login")
    let { email, password } = user
    let result = await signInWithEmailAndPassword(auth, email, password);
    saveUserToken( result )

    return {result,undefined};
  } catch (err) {
    logService.log(err)
    if( err.code && 'auth/user-not-found' === err.code ) {
      err.message = "User not found."
    } else if( 'auth/wrong-password' === err.code ) {
      err.message = "Login failed."
    }
    return {undefined,err};
  }
};

const loginWithGoogle = async () => {
  try{
    logger.log("authService.loginWithGoogle")
    let result = await signInWithPopup(auth, new GoogleAuthProvider());
    saveUserToken( result )
    return result;
  } catch (err) {
    logService.log(err)
  }
}

const saveUserToken = result => {
  logger.log("authService.saveUserToken")
  if( result && result.user && result.user.accessToken ){
    localStorage.setItem( tokenKey, result.user.accessToken );
  }
}

/*const getUserToken = () => {
  logger.log("authService.getUserToken")
  let userAccessToken = localStorage.getItem( tokenKey );
  return userAccessToken;
}*/

export function useAuth(){
  // logger.log("authService.useAuth")
  return useAuthState(auth)
}

export function register(user) {
  logger.log("authService.register")
  return httpService.post(apiEndpoint + '/register', {
    displayName: user.displayName,
    email: user.email,
    password: user.password,
  })
}

export async function getAppUser( user ) {
  logger.log("authService.getAppUser")
  if(!user || !user.uid) return null;
  let result;
  try{
    result = await httpService.get( config.apiUrl + "/users/" + user.uid )
  } catch (err) {
    // todo: can't get app user!
    logger.log("appUser fetch error:", err)
  }

  if(!result) return null
  return result.data
}

const logout = async () => {
  logger.log("authService.logout")
  localStorage.removeItem( tokenKey );
  return signOut(auth);
};

const resetPassword = async ( email ) => {
  logger.log("authService.resetPassword");
  let result = await sendPasswordResetEmail( auth, email )
    .then(() => {
      return [true,false];
    })
    .catch((err) => {
      return [false,err];
    });

  return result;
}

export async function refreshToken(){
  logger.log("authService.refreshToken")
  let userToken = await auth.currentUser.getIdToken(true);
  if(userToken){
    localStorage.setItem( tokenKey, userToken );
  }
}

export async function getAuthHeader(){
  logger.log("authService.getAuthHeader")
  if( auth && auth.currentUser ){
    let userToken = await auth.currentUser.getIdToken(true); // getUserToken()

    if(!userToken && auth && auth.currentUser){
      refreshToken()
    }

    return 'Bearer ' + userToken
  } else {
    return false
  }
}

const authService = {
  auth,
  login,
  loginWithGoogle,
  logout,
  onAuthStateChanged,
  useAuth,
  register,
  resetPassword,
  getAuthHeader,
  getAppUser,
};

export default authService;

