import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import Footer from '../components/Footer';

const Layout = () => {
  return ( 
    <div id="app">
      <ToastContainer />
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;